$(function(){

    $('.slides').cycle({
        fx: 'fade',
        speed:  500,
        timeout: 0,
        next:   '#next',
        prev:   '#prev',
    });

    // jQuery for page scrolling feature - requires jQuery Easing plugin
    $('a.page-scroll').bind('click', function(event) {
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: ($($anchor.attr('href')).offset().top - 50)
        }, 1250, 'easeInOutExpo');
        event.preventDefault();
    });

    $('.play-vid').on('click', function() {
        var target = $(this).data('target');
        var div_id = target.replace(/^#/, '');
        $(this).parent().hide();
        $(target).show();
        var div = document.getElementById(div_id);
        var iframe = div.getElementsByTagName("iframe")[0].contentWindow;
        iframe.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
        event.preventDefault();
    });

    $('ul.video-thumbnails li').on('click', function() {
        $('ul.video-thumbnails li').removeClass('selected');
        $('ul.video-thumbnails li img').removeClass('grayscale-off');
        $(this).addClass('selected');
        $(this).children('img').addClass('grayscale-off');
        var target = $(this).data('target');
        $('.video').hide();

        $( ".video" ).each(function( index ) {
            var iframe = this.getElementsByTagName("iframe")[0].contentWindow;
            iframe.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
        });
        $(target).show();
    });

});