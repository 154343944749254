var mainMap = {};
mainMap.people = null;
mainMap.map = null;
mainMap.markerClusterer = null;
mainMap.markers = [];
mainMap.infoWindow = null;
mainMap.minZoomLevel = 3;
var clustererZoomLevel = 15;
var geocoder = {};
var miniMap = {};
miniMap.marker = null;
var markerSpiderfier = {};
var new_marker = null;

mainMap.init = function() {
    var latlng = new google.maps.LatLng(39.4, -39.8);
    var options = {
        'zoom': mainMap.minZoomLevel,
        'center': latlng,
        'mapTypeId': google.maps.MapTypeId.ROADMAP
    };

    map = new google.maps.Map(document.getElementById('map'), options);

    mainMap.map = map;

    mainMap.people = data;

    // Limit the zoom level
    google.maps.event.addListener(map, 'zoom_changed', function () {
        if (map.getZoom() < mainMap.minZoomLevel) map.setZoom(mainMap.minZoomLevel);
    });

    mainMap.infoWindow = new google.maps.InfoWindow({pixelOffset: new google.maps.Size(0, -32)});
    mainMap.showMarkers();

    if(window.location.hash) {
        var selected = window.location.hash.substr(1);
        var user_index  = _.findIndex(mainMap.people, function(p) { return p.code.substring(0, 10) == selected });
        var user = mainMap.people[user_index];
        map.setCenter(new google.maps.LatLng(user.latitude, user.longitude));
        map.setZoom(13);
        new google.maps.event.trigger(mainMap.markers[user_index], 'click');
        updateSocialTags(user);
    }
};

mainMap.showMarkers = function() {
    var spiderConfig = {
        keepSpiderfied: true,
        event: 'mouseover'
    };

    var clustererOptions = {
        styles: [
            {
                textColor: 'white',
                url: 'https://cdn.rawgit.com/googlemaps/js-marker-clusterer/gh-pages/images/m1.png',
                height: 52,
                width: 53
            },
            {
                textColor: 'white',
                url: 'https://cdn.rawgit.com/googlemaps/js-marker-clusterer/gh-pages/images/m2.png',
                height: 55,
                width: 56
            },
            {
                textColor: 'white',
                url: 'https://cdn.rawgit.com/googlemaps/js-marker-clusterer/gh-pages/images/m3.png',
                height: 65,
                width: 66
            },
            {
                textColor: 'white',
                url: 'https://cdn.rawgit.com/googlemaps/js-marker-clusterer/gh-pages/images/m4.png',
                height: 77,
                width: 78
            },
            {
                textColor: 'white',
                url: 'https://cdn.rawgit.com/googlemaps/js-marker-clusterer/gh-pages/images/m5.png',
                height: 89,
                width: 90
            }
        ]
    };

    markerSpiderfier = new OverlappingMarkerSpiderfier(map, spiderConfig);

    peopleLength = mainMap.people.length
    var markers2 = [];
    for (var i = 0; i < peopleLength; i++) {
        var p = mainMap.people[i];
        marker = mainMap.addMarker(p);
        mainMap.markers.push(marker);
        markerSpiderfier.addMarker(marker);  // Adds the Marker to OverlappingMarkerSpiderfier

    }
    mainMap.markerClusterer = new MarkerClusterer(mainMap.map, mainMap.markers, clustererOptions);
    mainMap.markerClusterer.setMaxZoom(clustererZoomLevel);

};

mainMap.addMarker = function(person) {
    var latLng = new google.maps.LatLng(person.latitude, person.longitude);

      var imageUrl = '//chart.googleapis.com/chart?cht=mm&chs=24x32&chco=' +
            'FFFFFF,008CFF,000000&ext=.png';
        var markerImage = new google.maps.MarkerImage(imageUrl, new google.maps.Size(24, 32));

        var marker = new google.maps.Marker({
          'position': latLng,
          'icon': markerImage
        });

        var fn = mainMap.markerClickFunction(person, latLng);
        google.maps.event.addListener(marker, 'click', fn);
        return marker;
}


mainMap.markerClickFunction = function(person, latlng) {
    return function(e) {
        if (e) {
            e.cancelBubble = true;
            e.returnValue = false;
            if (e.stopPropagation) {
                e.stopPropagation();
                e.preventDefault();
            }
        }
        var title = person.name;
        var comments = person.comments;
        if (comments) {
            comments = '<div class="info-comments">' + comments.replace(/(?:\r\n|\r|\n)/g, '<br />') + '</div>';
        }

        var owned = ownedWhat(person);
        if (owned) {
            owned = '<div class="info-owned"><strong>Owned</strong><br>' + owned + '</div>';
        }

        var owner_since = person.owner_since;
        if (owner_since) {
            owner_since = "<div class='info-owner'><strong>Owner Since</strong><br>" + owner_since + "</div>";
        }

        var image = '';
        if (person.picture) {
            image = '<img src="/uploads/' + person.picture + '" class="info-img">';
        }
        owned = '<p>' + owned + '</p>';

        var infoHtml = '<div class="info"><h3>'
            + title + '</h3><div class="info-body">' +
          image + comments + owner_since + owned +
          '</div></div>';

        mainMap.infoWindow.setContent(infoHtml);
        mainMap.infoWindow.setPosition(latlng);
        mainMap.infoWindow.open(mainMap.map);
    };
};


function initMap() {
    miniMap = new google.maps.Map(document.getElementById('mini'), {
        zoom: 4,
        center: {lat: 44.5617189, lng: -72.59844859999998}
    });

    geocoder = new google.maps.Geocoder();

    document.getElementById('geocode').addEventListener('click', function() {
        geocodeAddress(geocoder, miniMap);
    });

    document.getElementById('geolocate').addEventListener('click', function(e) {
        geolocateAddress(miniMap);
        e.returnValue = false;
        e.preventDefault();
        e.stopPropagation();
    });

    document.getElementById('address').addEventListener('keyup', function(e) {
        if (e.keyCode == 13) {
          e.returnValue = false;
          e.preventDefault();
          e.stopPropagation();
          geocodeAddress(geocoder, miniMap);
          return false;
        }
    });

}

function geocodeAddress(geocoder, map) {
    var address = document.getElementById('address').value;
    geocoder.geocode({'address': address}, function(results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
            pos = results[0].geometry.location;
            findLocationOnMap(map, pos);
        } else {
            alert('Geocode was not successful for the following reason: ' + status);
        }
    });
}

function updatePositions(pos) {
    jQuery('#longitude').val(pos.lng);
    jQuery('#latitude').val(pos.lat);
}

function findLocationOnMap(map, pos) {
    map.setCenter(pos);
    map.setZoom(15),
    setMarker(map, pos);
    updatePositions(pos);
}

function geolocateAddress(map) {
    // Try HTML5 geolocation.
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(position) {
            var pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };
            findLocationOnMap(map, pos);
        }, function() {
            var infoWindow = new google.maps.InfoWindow({map: map});
            handleLocationError(true, infoWindow, map.getCenter());
        });

    } else {
        // Browser doesn't support Geolocation
        var infoWindow = new google.maps.InfoWindow({map: map});
        handleLocationError(false, infoWindow, map.getCenter());
    }
}

function geocodePosition(pos) {

    geocoder.geocode({
        latLng: pos
    }, function (responses) {

        if (responses && responses.length > 0) {
            updateMarkerAddress(responses[0].formatted_address);
        } else {
            updateMarkerAddress('Cannot determine address at this location.');
        }
    });

}

function setMarker(map, pos) {
    if (miniMap.marker) {
        miniMap.marker.setMap(null);
    }
    miniMap.marker = new google.maps.Marker({
        map: map,
        position: pos,
        draggable: true
    });


    google.maps.event.addListener(miniMap.marker, 'dragend', function () {
        updatePositions(miniMap.marker.getPosition());
    });
}

function handleLocationError(browserHasGeolocation, infoWindow, pos) {
    infoWindow.setPosition(pos);
    infoWindow.setContent(browserHasGeolocation ?
        'Error: The Geolocation service failed.' :
        'Error: Your browser doesn\'t support geolocation.');
}

function ownedWhat(person) {
    var owned = [];
    if (person.model_a) {
        owned.push('Model A');
    }
    if (person.model_b) {
        owned.push('Model B');
    }
    if (person.model_c) {
        owned.push('Model C');
    }
    if (person.model_d) {
        owned.push('Model D');
    }
    if (person.model_e) {
        owned.push('Model E');
    }
    if (person.dynamic) {
        owned.push('Dynamic');
    }
    if (person.skierg1) {
        owned.push('SkiErg1');
    }
    if (person.skierg2) {
        owned.push('SkiErg2');
    }
    if (person.oars) {
        owned.push('Oars');
    }
    return owned.join(', ');
}

$(function(){
    $('#entry').on('shown.bs.collapse', function (e) {
        initMap();
    });

    $('#entry').on('show.bs.collapse', function (e) {
        $('#success').collapse('hide');
    });

    $('#retry').on('click', function (e) {
        $('#deleted').collapse('hide');
    });

    $('#delete').on('click', function (e) {
        formdata = new FormData($('#delete-form')[0]);
        // process the form
        $.ajax({
            type        : 'POST',
            url         : '/map/api/' + $('#code').val(),
            dataType    : 'json',
            data        : formdata,
            // THIS MUST BE DONE FOR FILE UPLOADING
            contentType: false,
            processData: false,
        })
            // using the done promise callback
            .done(function(data) {
                $('#success').collapse('hide');
                $('#deleted').collapse('show');
                if (new_marker) {
                    mainMap.markerClusterer.removeMarker(new_marker);
                    markerSpiderfier.removeMarker(new_marker);
                }
                $('#create-form').attr('action', '/map/api');
            })
            .error(function(err) {
                alert("Sorry! There has been an error deleting this. Please contact website@concept2.com for help");
            });
      e.returnValue = false;
      e.preventDefault();
      e.stopPropagation();
      return false;

    });

    var comment_max = 500;
    $('#comments-help').html('Maximum ' + comment_max + ' characters!');

    $('#story').keyup(function() {
        var comment_length = $('#story').val().length;
        var comment_remaining = comment_max - comment_length;

        $('#comments-help').html(comment_remaining + ' characters remaining');
    });

  $('#create-form').on('submit', function(e){
        $('#create-submit').prop('disabled', true);
        var action = $(this).attr('action');
        formdata = new FormData($('#create-form')[0]);
        // process the form
        $.ajax({
            type        : 'POST',
            url         : action,
            dataType    : 'json',
            data: formdata,
            // THIS MUST BE DONE FOR FILE UPLOADING
            contentType: false,
            processData: false,
        })
            // using the done promise callback
            .done(function(data) {
                $('.collapse-form').collapse('hide');
                $('#success').collapse('show');
                if (data.success.code) {
                    $('#code').val(data.success.code);
                    window.location.hash = data.success.code.substring(0, 10);
                }
                if (new_marker) {
                    mainMap.markerClusterer.removeMarker(new_marker);
                    markerSpiderfier.removeMarker(new_marker);
                }
                new_marker = mainMap.addMarker(data.success);
                mainMap.markerClusterer.addMarker(new_marker);
                markerSpiderfier.addMarker(new_marker);  // Adds the Marker to OverlappingMarkerSpiderfier

                mainMap.map.setCenter(new google.maps.LatLng(data.success.latitude, data.success.longitude));
                mainMap.map.setZoom(14);
                $('#create-form').attr('action', '/map/api/' + data.success.code);
                $('#added-name-value').text(data.success.name);
                if (data.success.story) {
                    $('#added-comments').removeClass('hidden');
                    $('#added-comments-value').html(data.success.story);
                }
                if (data.success.owner_since) {
                    $('#added-owner-since').removeClass('hidden');
                    $('#added-owner-since-value').text(data.success.owner_since);
                }
                if (data.success.picture) {
                    $('#added-picture').removeClass('hidden');
                    $('#added-picture-value').html('<img src="/uploads/' + data.success.picture + '" class="info-img">');
                }
                var owned = ownedWhat(data.success);
                if (owned) {
                    $('#added-owned').removeClass('hidden');
                    $('#added-owned-value').html(owned);
                }
                setCopy();
                updateSocialTags(data.success);
                $('#create-submit').prop('disabled', false);
            })
            .error(function(err) {
                var errors = err.responseJSON;
                if (errors.name) {
                    $('#name-group').addClass('has-error'); // add the error class to show red input
                    $('#name-group').append('<div class="help-block">' + errors.name[0] + '</div>'); // add the actual error message under our input
                }
                if (errors.email) {
                    $('#email-group').addClass('has-error'); // add the error class to show red input
                    $('#email-group').append('<div class="help-block">' + errors.email[0] + '</div>'); // add the actual error message under our input
                }
                if (errors.latitude || errors.longitude) {
                    $('#location-group').addClass('has-error'); // add the error class to show red input
                    $('#location-group').append('<div class="help-block">Please make sure you include your location!</div>'); // add the actual error message under our input
                }
                if (errors.picture) {
                    $('#picture-group').addClass('has-error'); // add the error class to show red input
                    $('#picture-group').append('<div class="help-block">' + errors.picture[0] + '</div>'); // add the actual error message under our input
                }
            });

        e.returnValue = false;
        e.preventDefault();
        e.stopPropagation();
        return false;
    });

    $('#share_facebook').click(function(e) {
        e.returnValue = false;
        e.preventDefault();
        e.stopPropagation();
        FB.ui({
            method: 'feed',
            link: window.location.href
        }, function(response){});
    });

    function setCopy() {
        var clipboard = new Clipboard('#copy');
        clipboard.on('success',function(e) {
            $('#copy').tooltip({ title: 'Copied!', delay: { "show": 0, "hide": 1000 }, placement: 'bottom'});
        });
    }

    function updateSocialTags(person) {
        var url = window.location.href;
        var string = 'https://twitter.com/intent/tweet?url=https://40.concept2.com/map&amp;text=I+have+added+myself+to+the+World+of+Concept2!&amp;hashtags=MyConcept2';
        string = string.replace('https://40.concept2.com/map', encodeURIComponent(url));
        $('#share_twitter').attr('href', string);
        $('#share_link').val(url);
        $("meta[property='og\\:url']").attr("content", url);
        $("meta[property='og\\:description']").attr("content", "I've added myself to the World of Concept2! Check out where I am and see how far Concept2 has grown in its 40 years!");
    }

});
